
*,
*:before,
*:after { box-sizing: border-box; }

body {
	font-family: 'Helvetica Neue', sans-serif;
	line-height: 1;
}
body .lineHeightCondentsed{
	line-height: 1!important;
}
.numbers {
	padding-top: 3rem;
	text-align: center;
}

.digit {
	position: relative;
	font-size: 45px;
	font-weight: bold;
	background-color:#d19a9d;
	color: white;
	line-height: 1;
	padding: 0 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-left: 2.5px;
	margin-right: 2.5px;
	border-radius: 5px;
	border-width: 3px;
    border-color: white;
    border-style: solid;
	display: inline-block;
    width: 45px;
	top: -10px;

	@media screen and (min-width: 768px) {
		display: inline-block;
    	width: 43px;
		top: -10px;
	}
	
	&:before {
		content: '';
		display: block;
		background: black;
		opacity: 0.20;
		height: 1px;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
	}
	
	&:after {
		content: '';
		display: block;
		background: black;
		opacity: 0.10;
		height: 50%;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		border-bottom-left-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}
}

.overlayImage {
	position:absolute;
	right:-3.4px;
	top:25px;
	width:45px;

	@media screen and (max-width: 768px) {
		display: inline-block;
    	width: 22px;
		top: 15px;
		left: -1px;
	}

	@media screen and (min-width: 768px) {
		display: inline-block;
    	width: 40px;
		top: 25px;
		left: -1px;
	}
}


.digit-mobile {
	position: relative;
	font-size: 15px;
	font-weight: bold;
	background-color:#d19a9d;
	color: white;
	line-height: 1;
	padding: 0 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-left: 2.5px;
	margin-right: 2.5px;
	border-radius: 5px;
	
	&:before {
		content: '';
		display: block;
		background: black;
		opacity: 0.20;
		height: 1px;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
	}
	
	&:after {
		content: '';
		display: block;
		background: black;
		opacity: 0.10;
		height: 50%;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		border-bottom-left-radius: 0.8rem;
		border-bottom-right-radius: 0.8rem;
	}
}

// non-responsive message
@media all and (max-width: 200px) {
	body:before {
		content: "Unfortunately, this pen is not completely responsive and is still a work in progress. For the full effect, please view this pen on a screen 550px or wider.";
		display: block;
		text-align: center;
		padding: 3rem;
		line-height: 1.5;
		font-size: 2.1rem;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color:#d19a9d;
		color: white;
	}
	
	.numbers { display: none; }
}