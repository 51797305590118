body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

br {
  display: block;
  margin: 16px 0;
  line-height: 22px;
  content: " ";
}

.scrollbar {
  margin-left: 30px;
  float: left;
  height: 100px;
  width: 65px;
  background: #f5f5f5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

.force-overflow {
  min-height: 450px;
}

#wrapper {
  text-align: center;
  width: 500px;
  margin: auto;
}

#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

#testsvg {
  position: fixed;
  left: 0px;
  top: 10px;
  z-index: -1;
  opacity: 1;
}

.new-Link {
  
}

.new-link:hover {
  text-shadow: 0px 0px 1px gray;
  border-bottom-color: #d5d5d5;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}