.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

br {
  display: block;
  margin: 10px 0;
}
div.circTxt {
  /*allows for centering*/
  display: inline-block;
  /*adjust as needed*/
  margin-bottom: 128px;
  color: whitesmoke;
}

.lds-spinner {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-40px, -40px);
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(155, 155, 155);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.move-me-1 {
  animation:  float 4s ease-out infinite;
  -webkit-animation:  float 4s ease-out infinite;
}
.move-me-2 {
  animation: float1 2s ease-out infinite;
  -webkit-animation: float1 2s ease-out infinite;
}
.move-me-3 {
  animation: float2 4s ease-out infinite;
  -webkit-animation: float2 4s ease-out infinite;
}
.move-me-4 {
  animation: float3 4s ease-out infinite;
  -webkit-animation: float3 4s ease-out infinite;
}

.move-me-5 {
  animation: float4 6s ease-out infinite;
  -webkit-animation: float4 6s ease-out infinite;
}

.move-me-6 { /*for tablet*/
  animation: float5 4s ease-out infinite;
  -webkit-animation: float5 4s ease-out infinite;
}
.move-me-7 { /*for tablet*/
  animation: float6 4s ease-out infinite;
  -webkit-animation: float6 4s ease-out infinite;
}

.move-me-8 { /*for mobile*/
  animation: float7 4s ease-out infinite;
  -webkit-animation: float7 4s ease-out infinite;
}

.move-me-9 {
  /*formobile*/animation: float8 4s ease-out infinite;
  -webkit-animation: float8 4s ease-out infinite;
}



@keyframes float {
  0% {
    transform: translate(0, -80px);
    -webkit-transform: translate(0, -80px);
    -moz-transform: translate(0, -80px);
    -ms-transform: translate(0, -80px);
    -o-transform: translate(0, -80px);
}
  50% {
    transform: translate(0, 5px);
    -webkit-transform: translate(0, 5px);
    -moz-transform: translate(0, 5px);
    -ms-transform: translate(0, 5px);
    -o-transform: translate(0, 5px);
}
  100% {
    transform: translate(0px, 5px);
    -webkit-transform: translate(0px, 5px);
    -moz-transform: translate(0px, 5px);
    -ms-transform: translate(0px, 5px);
    -o-transform: translate(0px, 5px);
}
}

@keyframes float1 {
  0% {
    transform: translate(0, -50px);
    -webkit-transform: translate(0, -50px);
    -moz-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    -o-transform: translate(0, -50px);
}
  50% {
    transform: translate(0, 5px);
    -webkit-transform: translate(0, 5px);
    -moz-transform: translate(0, 5px);
    -ms-transform: translate(0, 5px);
    -o-transform: translate(0, 5px);
}
  100% {
    transform: translate(0, 5px);
    -webkit-transform: translate(0, 5px);
    -moz-transform: translate(0, 5px);
    -ms-transform: translate(0, 5px);
    -o-transform: translate(0, 5px);
}
}

@keyframes float2 {
  0% {
    transform: translate(0, 200px);
    
  }
  50% {
    transform: translate(0, 520px);
    -webkit-transform: translate(0, 520px);
    -moz-transform: translate(0, 520px);
    -ms-transform: translate(0, 520px);
    -o-transform: translate(0, 520px);
}
  75% {
    transform: translate(10px,510px);
    -webkit-transform: translate(10px,510px);
    -moz-transform: translate(10px,510px);
    -ms-transform: translate(10px,510px);
    -o-transform: translate(10px,510px);
}
  100% {
    transform: translate(20px, 520px);
  }
}
@keyframes float3 {
  0% {
    transform: translate(0, 200px);
    -webkit-transform: translate(0, 200px);
    -moz-transform: translate(0, 200px);
    -ms-transform: translate(0, 200px);
    -o-transform: translate(0, 200px);
  }
  50% {
    transform: translate(0, 690px);
    -webkit-transform: translate(0, 690px);
    -moz-transform: translate(0, 690px);
    -ms-transform: translate(0, 690px);
    -o-transform: translate(0, 690px);
  }
  75% {
    transform: translate(10px,680px);
    -webkit-transform: translate(10px,680px);
    -moz-transform: translate(10px,680px);
    -ms-transform: translate(10px,680px);
    -o-transform: translate(10px,680px);
  }
  100% {
    transform: translate(20px, 690px);
    -webkit-transform: translate(20px, 690px);
    -moz-transform: translate(20px, 690px);
    -ms-transform: translate(20px, 690px);
    -o-transform: translate(20px, 690px);
  }
}

@keyframes float4 {
  0% {
    transform: translate(0, 200px);
    -webkit-transform: translate(0, 200px);
    -moz-transform: translate(0, 200px);
    -ms-transform: translate(0, 200px);
    -o-transform: translate(0, 200px);
  }
  50% {
    transform: translate(0, 900px);
    -webkit-transform: translate(0, 900px);
    -moz-transform: translate(0, 900px);
    -ms-transform: translate(0, 900px);
    -o-transform: translate(0, 900px);
  }
  75% {
    transform: translate(10px,890px);
    -webkit-transform: translate(10px,890px);
    -moz-transform: translate(10px,890px);
    -ms-transform: translate(10px,890px);
    -o-transform: translate(10px,890px);
  }
  100% {
    transform: translate(20px, 900px);
    -webkit-transform: translate(20px, 900px);
    -moz-transform: translate(20px, 900px);
    -ms-transform: translate(20px, 900px);
    -o-transform: translate(20px, 900px);
  }
}

@keyframes float5 {
  0% {
    transform: translate(0, 200px);
    -webkit-transform: translate(0, 200px);
    -moz-transform: translate(0, 200px);
    -ms-transform: translate(0, 200px);
    -o-transform: translate(0, 200px);
  }
  50% {
    transform: translate(0, 480px);
    -webkit-transform: translate(0, 480px);
    -moz-transform: translate(0, 480px);
    -ms-transform: translate(0, 480px);
    -o-transform: translate(0, 480px);
  }
  75% {
    transform: translate(10px,470px);
    -webkit-transform: translate(10px,470px);
    -moz-transform: translate(10px,470px);
    -ms-transform: translate(10px,470px);
    -o-transform: translate(10px,470px);
  }
  100% {
    transform: translate(20px, 480px);
    -webkit-transform: translate(20px, 480px);
    -moz-transform: translate(20px, 480px);
    -ms-transform: translate(20px, 480px);
    -o-transform: translate(20px, 480px);
  }
}

@keyframes float6 {
  0% {
    transform: translate(0, 200px);
    -webkit-transform: translate(0, 200px);
    -moz-transform: translate(0, 200px);
    -ms-transform: translate(0, 200px);
    -o-transform: translate(0, 200px);
  }
  50% {
    transform: translate(0, 620px);
    -webkit-transform: translate(0, 620px);
    -moz-transform: translate(0, 620px);
    -ms-transform: translate(0, 620px);
    -o-transform: translate(0, 620px);
  }
  75% {
    transform: translate(10px,610px);
    -webkit-transform: translate(10px,610px);
    -moz-transform: translate(10px,610px);
    -ms-transform: translate(10px,610px);
    -o-transform: translate(10px,610px);
  }
  100% {
    transform: translate(20px, 620px);
    -webkit-transform: translate(20px, 620px);
    -moz-transform: translate(20px, 620px);
    -ms-transform: translate(20px, 620px);
    -o-transform: translate(20px, 620px);
  }
}

@keyframes float7 {
  0% {
    transform: translate(0, 0px);
    -webkit-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 280px);
    -webkit-transform: translate(0, 280px);
    -moz-transform: translate(0, 280px);
    -ms-transform: translate(0, 280px);
    -o-transform: translate(0, 280px);
  }
  75% {
    transform: translate(10px,270px);
    -webkit-transform: translate(10px,270px);
    -moz-transform: translate(10px,270px);
    -ms-transform: translate(10px,270px);
    -o-transform: translate(10px,270px);
  }
  100% {
    transform: translate(20px, 280px);
    -webkit-transform: translate(20px, 280px);
    -moz-transform: translate(20px, 280px);
    -ms-transform: translate(20px, 280px);
    -o-transform: translate(20px, 280px);
  }
}


@keyframes float8 {
  0% {
    transform: translate(0, 0px);
    -webkit-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 210px);
    -webkit-transform: translate(0, 200px);
    -moz-transform: translate(0, 200px);
    -ms-transform: translate(0, 200px);
    -o-transform: translate(0, 200px);
  }
  75% {
    transform: translate(10px,190px);
    -webkit-transform: translate(10px,190px);
    -moz-transform: translate(10px,190px);
    -ms-transform: translate(10px,190px);
    -o-transform: translate(10px,190px);
  }
  100% {
    transform: translate(20px, 200px);
    -webkit-transform: translate(20px, 200px);
    -moz-transform: translate(20px, 200px);
    -ms-transform: translate(20px, 200px);
    -o-transform: translate(20px, 200px);
  }
}

.numbers-pipe {
  height: 275px;
}
.hashtag {
  font-weight: bold;
  color: #f49fb6;
}
@media only screen and (min-width: 1300px) {
  .numbers-pipe {
    height: 325px;
  }
}



@media only screen and (max-width: 425px) {
  .ncis-button {
    width: 100% !important;
    font-size: 8px !important;
  }
}

@media only screen and (max-width: 425px) {
  .logo-image {
    height: 45px !important;
  }
}

@media only screen and (max-width: 350px) {
  .ncis-button {
    width: 100% !important;
    font-size: 7px !important;
  }
}

@media only screen and (min-width: 1500px) {
  .home-second-paragraph {
    margin-top: 100px !important;
  }

}

@media only screen and (min-width: 992px) {
  .supporting-partners-div, .supporting-partners-img {
    width: 70px !important;
    height: 44px !important;
    object-fit: contain;
  }
}

@media only screen and (min-width: 992px) {
  .sponsors-div, .sponsors-img  {
    width: 90px !important;
    height: 57px !important;
    object-fit: contain;
  }
}

@media only screen and (min-width: 992px) {
}




.floatPledge img.mainImg {
  width: 100%; 
  opacity: .9;
  height: 100%;
  position: static !important;
}
.floatPledge {
  width: 220px;
  position: fixed !important;
  /* top: 20%; */
  /* bottom: 42px; */
  z-index: 11;
  right: calc((33.3% - 220px)/2);
}
.floatPledge i{
  cursor: pointer;
  font-size: 25px;
  color: rgb(39, 31, 87);
  z-index: 3;
  position: absolute !important;
  /* top: 100%; */
  top: 87%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 !important;
  /* margin-top: -40px; */
} 
.floatPledge i:hover{
  opacity: .6;
}

@media(max-width: 767px){
  .floatPledge i{
    cursor: pointer;
    font-size: 20px;
    color: rgb(39, 31, 87);
    z-index: 3;
    position: absolute !important;
    /* top: 100%; */
    top: 87%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 !important;
    /* margin-top: -20px; */
  } 

  .floatPledge {
    width: 150px !important;
    /* top: 20%; */
    z-index: 11;
    right: 3%;
  }
  .heighLightedIcon > img {
    width: 55px !important;
    height: 55px !important;
    object-fit: contain;
    padding: 0 0 10px 0!important;
}
 .homeContentWrapr {
    padding-left: 15px !important;
  }
  .toggleMenuWrapr .navbar-toggler{
    margin-right: 0 !important;
    box-shadow: none !important;
    background: transparent !important;
  }
  .toggleMenuWrapr.pe-4{
    padding-right: 3px !important;
  }
}
/* @media(min-width: 768px) and (max-width: 1100px){
  .floatPledge {
    right: 0%;
  }
} */
@media(min-width: 768px) and (max-width: 1024px){
  
  .homeContentWrapr {
    padding-left: 35px !important;
  }
  .pledgeresponsiveImg{
    align-items: center;
    position: relative;
  }
  .cardContent.d-flex{
    top: 0 !important;
    height: 100% !important;
    padding-top: 43px !important;
  }
  .pledgeresponsiveImg img.shadow {
    height: auto !important;
    min-height: inherit !important;
    width: 280px !important;
  }
  .circleribbon {
    bottom: 10px !important;
  }
}
.recipentName {
  width: 75%;
  display: block;
  line-height: 22px !important;
}

.pb-from-footer {
  padding-bottom: 72px !important;
}

.mb-from-footer {
  margin-bottom: 72px !important;
}

@media(min-width: 205px) and (max-width: 305px){
  .floatPledge i{
    cursor: pointer;
    font-size: 20px;
    color: rgb(39, 31, 87);
    z-index: 3;
    position: absolute !important;
    /* top: 100%; */
    top: 87%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 !important;
    /* margin-top: -10px; */
  } 
  .background-mobile{
    background-size: 200% !important;
  }
}

@media(min-width: 345px) and (max-width: 400px){
  .floatPledge i{
    cursor: pointer;
    font-size: 20px;
    color: rgb(39, 31, 87);
    z-index: 3;
    position: absolute !important;
    /* top: 100%; */
    top: 87%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 !important;
    /* margin-top: -10px; */
  } 

  .background-mobile{
    background-size: 120% !important;
  }
}
.progressBarWrapr > div {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media(min-width: 768px) and (max-width: 991px){
  .floatPledge i{
    cursor: pointer;
    font-size: 25px;
    color: rgb(39, 31, 87);
    z-index: 3;
    position: absolute !important;
    /* top: 100%; */
    top: 87%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 !important;
    /* margin-top: -30px; */
  } 

  .homeBgWrapr{
    background-position-x: -122px  !important;
  }
  .home-second-paragraph >div{
    padding-left: 30px !important;  
  }
}

@media(min-width: 600px) and (max-width: 767px){
  .homeRibbonBtn > button > img{
    margin-left: 165px !important;
  }

}
